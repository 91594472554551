import React from "react";
import scheduleBannerSM from "../Images/scheduleBannerSM.jpg";

import "../Styles/SetSchedule2.css";

const SetSchedule2 = () => {
  return (
    <div>
      <header className="sch-header">
        <img
          src={scheduleBannerSM}
          alt="Events Banner"
          className="sch-banner"
        />
      </header>
      <div className="sch-content">
        <h2 style={{ margin: "0" }} className="gold-text">
          JAN 11, 2025 • GAINESVILLE , FL
        </h2>
        <h1 style={{ margin: "0" }}>SET TIMES</h1>
        <h2 style={{ margin: "0" }}>EVENT STARTS AT 1 P M</h2>
        <table className="spaced-table table-content">
          <tr className="gold-text">
            <th>
              <h2 style={{ margin: "0" }}>Depot Park</h2>
            </th>
            <th>
              <h2 style={{ margin: "0" }}>Heartwood Soundstage</h2>
            </th>
          </tr>
          <tr>
            <td>
              Little Jake & The Soul Searchers –
              <span className="gold-text"> 1:30 p.m.</span>
            </td>
            <td>
              Purple Kloud – <span className="gold-text"> 1:30 p.m.</span>
            </td>
          </tr>
          <tr>
            <td>
              The Savants –<span className="gold-text"> 2:30 p.m.</span>
            </td>
            <td>
              Nancy Luca –<span className="gold-text"> 2:25 p.m.</span>
            </td>
          </tr>
          <tr>
            <td>
              Dikembe – <span className="gold-text"> 3:30 p.m.</span>
            </td>
            <td>
              Noah Moses & The Flood –
              <span className="gold-text"> 3:20 p.m.</span>
            </td>
          </tr>
          <tr>
            <td>
              Cliff Dorsey Band – <span className="gold-text"> 4:30 p.m.</span>
            </td>
            <td>
              The Lowly Worm – <span className="gold-text"> 4:15 p.m.</span>
            </td>
          </tr>
          <tr>
            <td>
              Chuck Ragan & The Camaraderie –{" "}
              <span className="gold-text"> 5:30 p.m.</span>
            </td>
            <td>
              Kristen Warren – <span className="gold-text"> 5:10 p.m.</span>
            </td>
          </tr>
          <tr>
            <td>
              Kingfish Ingram – <span className="gold-text"> 6:50 p.m.</span>
            </td>
            <td></td>
          </tr>
        </table>
        <h2 className="gold-text">
          *PLEASE NOTE SET TIMES ARE SUBJECT TO CHANGE
        </h2>
      </div>
    </div>
  );
};

export default SetSchedule2;
