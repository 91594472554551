import React from "react";

import "../Styles/SetSchedule2.css";

const EventPopup = () => {
  return (
    <div>
      {/* <header className="sch-header">
        <img
          src={scheduleBannerSM}
          alt="Events Banner"
          className="sch-banner"
        />
      </header> */}
      <div className="sch-content">
        <h1 style={{ margin: "0" }} className="gold-text">
          Parking Tips for<br></br> Alachua County's Discover 200 - A
          Bicentennial Celebration!{" "}
        </h1>
        <h2 style={{ margin: "0" }} className="gold-text">
          Planning to join us at Depot Park? Here’s everything you need to know
          about parking
        </h2>
        <ul
          style={{
            textAlign: "left",
            paddingRight: "10px",
            fontSize: "large",
          }}
        >
          <li>
            Arrive Early for Closer Parking Downtown Gainesville has plenty of
            parking options, and the earlier you arrive, the better your spot!
          </li>
          <li>
            City of Gainesville parking is free on Saturdays Enjoy free
            on-street parking, surface lots, and metered spaces (except the
            City's SW Downtown Garage).{" "}
          </li>
          <li>
            There are many city, county and state government parking lots
            available on Saturdays in central downtown (in the vicinity of
            University Avenue and Main Street) that are a short walk to Depot
            Park/Heartwood. (Click
            <a
              className="gold-text"
              href="https://tinyurl.com/k29uefh8"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              here{" "}
            </a>
            to the Alachua County Courthouse parking area as an example)
          </li>
          <li>
            Find Parking Fast. Use this great City of Gainesville, FL -
            Government interactive map. Just type 874 SE 4th St., Gainesville,
            to explore your options{" "}
            <a
              className="gold-text"
              href="https://experience.arcgis.com/experience/b8697d71afb7476da4c1efd862aeecb6/"
              target="_blank"
              rel="noreferrer"
            >
              here !
            </a>
          </li>
          <li>
            Depot Park Area Notes: Click{" "}
            <a
              className="gold-text"
              href="https://tinyurl.com/3mb6d35p"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            for a colorful parking map for lots closest to Depot Park and
            Heartwood.
          </li>
          <li>
            A short section of Depot Avenue at Main Street will be closed, so
            use alternate routes from your map app.
          </li>
        </ul>
        <h2 style={{ margin: "0" }} className="gold-text">
          Garage Options for Easy Parking
        </h2>{" "}
        <ul
          style={{
            textAlign: "left",
            paddingRight: "10px",
            fontSize: "large",
          }}
        >
          <li>
            City of Gainesville SW Downtown Garage:{" "}
            <a
              className="gold-text"
              href="https://maps.app.goo.gl/JmrJg5TbxuAjuoiz7"
              target="_blank"
              rel="noreferrer"
            >
              105 SW 3rd St.
            </a>
          </li>
          <li>
            Private SE Downtown Garage:{" "}
            <a
              className="gold-text"
              href="https://maps.app.goo.gl/Mz51PurA5JQiPoLt5"
              target="_blank"
              rel="noreferrer"
            >
              220 SE 2nd Ave.
            </a>
          </li>
        </ul>
        <h2 style={{ margin: "0" }} className="gold-text">
          {" "}
          Skip Parking Altogether{" "}
        </h2>{" "}
        <ul
          style={{
            textAlign: "left",
            paddingRight: "10px",
            fontSize: "large",
          }}
        >
          <li>
            Take an Uber or Lyft! We recommend getting dropped off a few blocks
            away to avoid traffic near Depot Park. <br />
            For more parking details, visit:{" "}
            <a
              className="gold-text"
              href="https://www.gainesvillefl.gov/Government-Pages/Government/Departments/Transportation/GNV-Parking"
              target="_blank"
              rel="noreferrer"
            >
              City of Gainesville parking info.
            </a>
          </li>
        </ul>
        <h2 style={{ margin: "0" }} className="gold-text">
          See you at 1 p.m. at #Discover200 for a day of celebration and fun!
        </h2>
      </div>
    </div>
  );
};

export default EventPopup;
