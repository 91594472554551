import React from "react";
import Menu from "../Components/Menu";
import eventHeader from "../Images/hero-eventPg.png";
import "../Styles/EventsPage.css";
import instaIcon from "../Images/instaIcon.png";
import fbIcon from "../Images/fbIcon.png";
import xIcon from "../Images/twitterIcon.png";
import ytIcon from "../Images/ytIcon.png";
import flickrIcon from "../Images/flickr-white-icon.png";
import SetSchedule2 from "./SetSchedule2";
import EventPopup from "./eventpopup";
import updatedEventPosterSM from "../Images/sm_NewEndTime-200th-poster.jpg";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
const EventsPage = () => {
  return (
    <div>
      {/* Header Section */}
      <Menu />

      <header className="events-header">
        <img
          src={eventHeader} // Replace with your banner image URL
          alt="Events Banner"
          className="events-banner"
        />
      </header>
      <div className="section-container gap50 poster-section">
        <div
          className="small-content blue-text"
          style={{ alignSelf: "flex-start" }}
        >
          <div
            className="h3-heading"
            style={{
              fontWeight: "bold",
              lineHeight: "0.9",
              textAlign: "left",
            }}
          >
            Saturday, Jan. 11, 2025 <br />
            from 1-8 p.m.
          </div>
          <p className="text-para">
            Alachua County invites you to join us for Discover 200 – A
            Bicentennial Celebration, a free, family-friendly event. This event
            marks the start of a yearlong celebration honoring the founding of
            Alachua County.
            <br /> <br />
            The event includes music on two stages at Depot Park and Heartwood
            Soundstage. There will be food trucks, free face paintings and
            balloon animals for kids, a strolling magician, historical and
            county service exhibits, UF mascots Albert and Alberta and much
            more.
            <br />
            <br /> We look forward to celebrating Alachua County’s birthday with
            you.
            <br />
            <div style={{ display: "grid" }}>
              <Popup
                trigger={
                  <button className="popup-buttons2" id="popup-1">
                    Set Schedule
                  </button>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <button className="popup-buttons" onClick={() => close()}>
                        Close X
                      </button>
                    </div>

                    <SetSchedule2 />
                  </div>
                )}
              </Popup>
              <Popup
                trigger={
                  <button className="popup-buttons2 " id="popup-1">
                    Parking Information
                  </button>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <button className="popup-buttons" onClick={() => close()}>
                        Close X
                      </button>
                    </div>

                    <EventPopup />
                  </div>
                )}
              </Popup>
            </div>
          </p>
        </div>
        <div className="large-content event-poster-container">
          <img
            className="event-poster"
            src={updatedEventPosterSM}
            alt="Discoover 200 Event Poster"
          />
          <div
            className="blue-text h2-heading"
            style={{
              textAlign: "center",
            }}
          ></div>
        </div>
      </div>
      <div className="blue-background full-page-padding">
        <div>
          <div className="social-media-icons-container">
            <div className="social-media-icons">
              <a href="https://x.com/alachuacounty">
                <img className="smi-images" src={xIcon} alt="Twitter Icon" />
              </a>
              <a href="https://www.youtube.com/alachuacounty">
                <img src={ytIcon} alt="YT Icon" className="smi-images" />
              </a>
              <a href="https://www.facebook.com/AlachuaCounty/">
                <img className="smi-images" src={fbIcon} alt="FB Icon" />
              </a>
              <a href="https://www.instagram.com/alachuacounty/">
                <img className="smi-images" src={instaIcon} alt="Insta Icon" />
              </a>
              <a href="https://www.flickr.com/photos/66143513@N03/">
                <img
                  className="smi-images"
                  src={flickrIcon}
                  alt="Flickr Icon"
                />
              </a>
            </div>
          </div>
          <div style={{ color: "white", textAlign: "left" }}>
            <div className="h3-heading">Disclaimer</div>
            <div className="disclaimer-text">
              <p>
                Alachua County aims to continually improve the accessibility and
                usability of its website. If you are an individual with a
                disability and you experience difficulty or require assistance
                or accommodation in using our website, please contact the
                Alachua County ADA Coordinator at ADA@alachuacounty.us or call
                the Alachua County Equal Opportunity Office at 352-374-5275;
                TDD/TTY Users please call 711 Florida Relay Service.
                <div style={{ textAlign: "center" }}>
                  <a
                    href="https://alachuacounty.us/Depts/EO/Pages/Website-Accessibility.aspx"
                    style={{ color: "white" }}
                  >
                    View Alachua County's Website Accessibility Policy And
                    Procedures
                  </a>
                </div>
              </p>
              <p>
                If you have a disability and need an accommodation in order to
                participate in a County program, service or public meeting,
                please contact the Equal Opportunity Office at 352-374-5275 at
                least 2 business days prior to the event. TDD users, please call
                711 (Florida Relay Service).
              </p>
              <p>
                To make a public records request, please contact the Public
                Records Custodian at (352) 264-6906 or{" "}
                <a
                  href="mailto:publicrecordsrequest@alachuacounty.us"
                  style={{ color: "white" }}
                >
                  publicrecordsrequest@alachuacounty.us.
                </a>{" "}
                Please visit the{" "}
                <a
                  href="/Depts/Communications/Pages/PublicRecordsRequest.aspx"
                  style={{ color: "white" }}
                >
                  Public Records Request
                </a>{" "}
                webpage for more information.
              </p>
              <p>
                This website is a public service. Please read the Legal
                Disclaimer. Website designed and engineered by Alachua County
                ITS, Applications Division Version 2014 (v1.0) Under Florida law
                (Statute 119.011), all information, including e-mail, written
                letters, documents and phone messages, sent to the Alachua
                County Board of County Commissioners is subject to Public
                Records law. This includes the sender's e-mail address, home
                address or phone number if shown in the message, the content of
                the message and any associated attachments to the mail. Also
                please be aware that electronic correspondence (e-mail) is made
                available on the Commission's public archive site immediately
                upon being sent. Instead, contact Alachua County Offices by
                phone or in writing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsPage;
